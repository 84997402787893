import HttpService from '../HttpService'

const _http = new HttpService()
export default {

  getUrlFile: (file_name, type) => {
    return _http.get(`/api/audio-visual/media-files/get-url-file?file_name=${file_name}&type=${type}`)
  },
  presentationFileS3Key: (id, payload) => {
    return _http.patch(`/api/audio-visual/media-files?id=${id}`, payload);
  },
  deletePdfMediaFile: (id) => {
    return _http.post(`/api/audio-visual/media-files/remove-pdf-media-file/${id}`);
  }
}