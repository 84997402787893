import HttpService from './HttpService'

const _http = new HttpService()
export default {
  mediaFile: (payload) => {
    return _http.post('/api/convert-pdf/media-file', payload)
  },
  mediaSubject: (payload) => {
    return _http.post('/api/convert-pdf/media-subject', payload)
  },
}