<template>
  <form
    class="component-body"
    :class="[lightMode ? 'component-body-light' : 'component-body-dark']"
    enctype="multipart/form-data"
  >
    <div class="dropbox" :class="[lightMode ? 'skin-light' : 'skin-dark']">
      <input
        ref="input-file"
        type="file"
        @change="fileChange($event.target.files)"
        :accept="fileType.join()"
        class="input-file"
      />
      <p
        v-if="Object.keys(file).length > 0 || newFile"
        class="m-1 pdf-card text-center text-main"
        :class="[lightMode ? 'pdf-card-light' : 'pdf-card-dark']"
      >
        <b-spinner
          v-if="removeLoading"
          small
          label="Small Spinner"
          style="position: absolute; top: 5px; right: 5px"
        />
        <feather-icon
          v-else
          class="m-0"
          icon="XIcon"
          style="position: absolute; top: 5px; right: 5px"
          size="16"
          @click="removePDF()"
        />
        <b-img
          @click="openFile"
          :src="iconPDF"
          fluid
          alt="Responsive image"
        ></b-img>
        <br />
        <b-progress
          v-if="newFile && newFile.progress && newFile.progress != 100"
          style="margin-top: 3px"
          max="100"
          :variant="newFile.error ? 'danger' : 'success'"
        >
          <b-progress-bar :value="newFile ? newFile.progress : 100" />
        </b-progress>
        <span v-else class="d-block text-truncate">{{
          newFile ? newFile.name : "Arquivo Vinculado"
        }}</span>
      </p>
      <p v-else class="text-center text-main" style="font-size: 9pt">
        <feather-icon class="m-25" icon="FilePlusIcon" size="20" />
        <br />
        Arraste e solte aqui<br />
        ou clique para buscar
      </p>
    </div>
  </form>
</template>

<script>
import { BImg, BProgress, BProgressBar, BSpinner } from "bootstrap-vue";
import useAppConfig from "@core/app-config/useAppConfig";
import AWS from "aws-sdk";
import _convertPDFService from "@/services/convert-pdf-service";
import _mediaFilesService from "@/services/audiovisual/media-files-service";

export default {
  setup() {
    const { skin } = useAppConfig();
    return {
      skin,
    };
  },
  components: {
    BProgress,
    BProgressBar,
    BImg,
    BSpinner,
  },
  props: {
    file: {
      type: Object,
    },
    mediaFileID: {
      required: false,
    },
    patchSave: {
      required: true,
      type: Boolean,
    },
    dirS3: {
      required: true,
      type: String,
    },
    fileType: {
      type: Array,
      required: true,
    },
    limitSizeFile: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    lightMode() {
      return this.skin == "light" ? true : false;
    },
  },
  data() {
    return {
      removeLoading: false,
      iconPDF: require("@/assets/images/icons/pdf2.png"),
      newFile: null,
    };
  },
  methods: {
    fileChange(fileList) {
      if (fileList == null) return;

      if (this.limitSizeFile > 0) {
        if (fileList[0].size > this.limitSizeFile * 1048576) {
          this.$utils.toastError(
            "Notificação",
            "Tamanho do arquivo excede 5.0 mb"
          );
          this.$emit("uploadFail");
          return false;
        }
      }

      if (Object.keys(this.file).length > 0) {
        this.$swal({
          title: "Confirmar Ação",
          text: "O arquivo anteriormente vinculado será substituido! Tem certeza?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Sim, continuar",
          cancelButtonText: "Cancelar",
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-danger ml-1",
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.value) {
            this.onUploadPdf(fileList[0]);
          }
        });
      } else {
        this.onUploadPdf(fileList[0]);
      }
    },
    openFile() {
      if (Object.keys(this.file).length > 0 && !this.newFile) {
        window.open(this.file.S3_url, "_blank");
      }
    },
    getKey(_name) {
      let result = `${Math.random().toString(16).substr(2)}${Math.random()
        .toString(16)
        .substr(2)}`;
      if (_name) {
        result += `.${_name.split(".")[_name.split(".").length - 1]}`;
      }
      return result;
    },
    onUploadPdf(file) {
      this.iniciarconfigs();
      this.$emit("initUpload");

      this.file.name = "";
      this.file.key = "";

      this.newFile = {
        name: file.name,
        S3_key: this.getKey(file.name),
        uploaded: false,
        progress: 0,
      };

      let payload = {
        bucket: `${process.env.VUE_APP_AWS_BUCKET_VIDEO}`,
        contentType: file.type,
        file: file,
        key: `${this.dirS3}/${this.newFile.S3_key}`,
      };

      this.sendFileS3(payload)
        .on("httpUploadProgress", (evt) => {
          this.newFile.progress = Math.round((evt.loaded / evt.total) * 100);
        })
        .send((err) => {
          if (err) {
            this.newFile.error = err;
            this.$utils.toastError("Falha no envio do arquivo", err);
            this.$emit("uploadFail");
            return false;
          } else {
            this.newFile.uploaded = true;
            if (this.patchSave) {
              this.convertPDF();
            } else {
              this.file.name = file.name;
              this.file.key = this.newFile.S3_key;
            }
            return true;
          }
        });
    },
    sendFileS3(_postfile) {
      return new AWS.S3.ManagedUpload({
        params: {
          ContentType: _postfile.contentType,
          Bucket: _postfile.bucket,
          Body: _postfile.file,
          Key: `${process.env.VUE_APP_AWS_BUCKET_DIR}/${_postfile.key}`,
        },
      });
    },
    iniciarconfigs() {
      AWS.config.update({
        accessKeyId: process.env.VUE_APP_AWS_ACESSKEY_ID,
        secretAccessKey: process.env.VUE_APP_AWS_ACESSKEY_SECRET,
      });
    },
    convertPDF() {
      _convertPDFService
        .mediaFile({ presentation_file_s3_key: this.newFile.S3_key })
        .then((res) => {
          this.newFile.S3_key = res.content;
          this.relatePDF();
        })
        .catch((error) => {
          this.$utils.toastError("Notificação", error);
          this.$emit("uploadFail");
        });
    },
    relatePDF() {
      const payload = [
        {
          path: "/presentation_file_s3_key",
          op: "replace",
          value: this.newFile.S3_key,
        },
      ];
      _mediaFilesService
        .presentationFileS3Key(this.mediaFileID, payload)
        .then((_res) => {
          this.$emit("newFile", this.newFile);
        })
        .catch((error) => {
          this.$utils.toastError("Notificação", error);
          this.$emit("uploadFail");
        });
    },
    removePDF() {
      if (this.removeLoading) return;

      this.$swal({
        title: "Deseja realmente apagar o arquivo?",
        text: "Os arquivos PDF vinculados aos assuntos também serão apagados!!!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sim, continuar",
        cancelButtonText: "Cancelar",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.removeLoading = true;
          
          this.$refs["input-file"].value = null;
          _mediaFilesService
            .deletePdfMediaFile(this.mediaFileID)
            .then((_res) => {
              if (this.newFile) this.newFile = null;
              this.$emit("newFile", {});
              this.$emit("remove");
            })
            .catch((error) => {
              this.$utils.toastError("Notificação", error);
              this.$emit("uploadFail");
            })
            .finally(() => {
              this.removeLoading = false;
            });
        }
      });
    },
  },
};
</script>

<style scoped>
.dropbox {
  outline: 2px dashed grey; /* the dash box */
  outline-offset: -5px;
  background: rgb(245, 245, 245);
  color: dimgray;
  padding: 10px 10px;
  position: relative;
  cursor: pointer;
  max-width: 300px;
}
.input-file {
  opacity: 0; /* invisible but it's there! */
  position: absolute;
  cursor: pointer;
  height: 85%;
  width: 95%;
}
.text-main {
  margin: 30px;
  line-height: 1.3;
}
.pdf-card {
  cursor: pointer;
  display: block;
  position: relative;
  text-align: center;
  padding: 15px;
  border-radius: 5px;
  max-width: 296px;
  margin: 2px;
  font-size: 9pt;
}
.pdf-card-light {
  background-color: #fff;
}
.pdf-card-dark {
  background-color: #41474c;
}
.component-body {
  max-width: 310px;
  padding: 5px;
  border-radius: 5px;
}
.component-body-light {
  background: white;
}
.component-body-dark {
  background: #333a3e;
  color: dimgray;
}
.skin-light {
  background: #f5f5f5;
}
.skin-dark {
  background: #333a3e;
  color: #b4b7bd;
}
</style>
