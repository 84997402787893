import HttpService from "../HttpService";
const _http = new HttpService();

export default {
  show: (page, options = null) => {
    let _parans = `?page=${page - 1}`;
    if (options) {
      if (options.search) _parans += `&search=${options.search}`;
      if (options.id) _parans += `&data.id=${options.id}`;
      if (options.teacher_id)
        _parans += `&data.teacher_id=${options.teacher_id}`;
      if (options.event_id) _parans += `&data.event_id=${options.event_id}`;
      if (options.course_id) _parans += `&data.course_id=${options.course_id}`;
      if (options.project_id)
        _parans += `&data.project_id=${options.project_id}`;
      if (options.status_id || options.status_id == 0) {
        _parans += `&data.status_id=${options.status_id}`;
        if (options.withFutureTrasmission != null)
          _parans += `&data.EditionJobsDTO.contains_transmission=${options.withFutureTrasmission}`;
      }
      if (options.media_type !== null) {
        _parans += `&data.media_type=${options.media_type}`
      }
      if (options.start_date) _parans += `&data.start_date=${options.start_date}`;
      if (options.end_date) _parans += `&data.end_date=${options.end_date}`;
      if (options.orderByKey) _parans += `&order_by=${options.orderByKey}`;
      if (options.ascOrDes) _parans += "&asc";

      if (options.finalized != undefined) _parans += `&data.finalized=${options.finalized}`;
      if (options.company_slug !== null) {
        _parans += `&data.company_slug=${options.company_slug}`
      } else if (options.company_id !== null) {
        _parans += `&data.company_id=${options.company_id}`
      };
    }
    return _http.get(`/api/audio-visual/recording-media/show${_parans}`); // NEW
    // return _http.get(`/api/audio-visual/recording-media${_parans}`)
  },
  find: (id) => {
    return _http.get(`/api/audio-visual/recording-media/${id}`);
  },
  create: (payload) => {
    return _http.post("/api/audio-visual/recording-media", payload);
  },
  update: (payload) => {
    return _http.put("/api/audio-visual/recording-media", payload);
  },
  delete: (id) => {
    return _http.delete(`/api/audio-visual/recording-media/${id}`);
  },
  updatePriority: (payload) => {
    return _http.put(
      "/api/audio-visual/recording-media/update-priority",
      payload
    );
  },
  showRecordingEditor: (page, size, params = null) => {
    let _parans = `?size=${size}&page=${page - 1}`;
    if (params) {
      if (params.is_transmission) _parans += `&data.is_transmission=${params.is_transmission}`;
      if (params.id) _parans += `&data.id=${params.id}`;
      if (params.teacher_id) _parans += `&data.teacher_id=${params.teacher_id}`;
      if (params.event_id) _parans += `&data.event_id=${params.event_id}`;
      if (params.company_id && params.company_id.length > 0) {
        params.company_id.forEach((id) => {
          _parans += `&data.companies_id=${id}`;
        });
      }
      if (params.product_id && params.product_id.length > 0) {
        params.product_id.forEach((id) => {
          _parans += `&data.products_id=${id}`;
        });
      }
      if (params.course_id) _parans += `&data.course_id=${params.course_id}`;
      if (params.project_id) _parans += `&data.project_id=${params.project_id}`;
      if (params.editor_id) _parans += `&data.editor_id=${params.editor_id}`;
      if (params.edition_status_id || params.edition_status_id == 0)
        _parans += `&data.status_id=${params.edition_status_id}`;
      if (params.upload_status)
        _parans += `&data.is_uploaded=${params.upload_status}`;
      if (params.validate_status_id || params.validate_status_id == 0)
        _parans += `&data.is_validated=${params.validate_status_id}`;
      if (params.priority != null)
        _parans += `&data.priority=${params.priority}`;
      if (params.start_date_recording != null)
        _parans += `&data.recording_start=${params.start_date_recording}`;
      if (params.end_date_recording != null)
        _parans += `&data.recording_end=${params.end_date_recording}`;
      if (params.start_date_upload != null)
        _parans += `&data.uploaded_at_start=${params.start_date_upload}`;
      if (params.end_date_upload != null)
        _parans += `&data.uploaded_at_end=${params.end_date_upload}`;
      if (params.orderByKey) _parans += `&order_by=${params.orderByKey}`;
      if (params.ascOrDes) _parans += "&asc";
    }
    return _http.get(
      `/api/audio-visual/recording-media/show-with-editor${_parans}`
    );
  },
  autoCompleteEditionCourse: (search) => {
    return _http.get(
      `/api/audio-visual/recording-media/auto-complete-edition-course?page=${0}&search=${search}&asc`
    );
  },
  autoCompleteEditionCompany: (search) => {
    return _http.get(
      `/api/audio-visual/recording-media/auto-complete-edition-company?page=${0}&search=${search}&asc`
    );
  },
  autoCompleteEditionEvent: (search) => {
    return _http.get(
      `/api/audio-visual/recording-media/auto-complete-edition-event?page=${0}&search=${search}&asc`
    );
  },
  autoCompleteEditionProject: (search) => {
    return _http.get(
      `/api/audio-visual/recording-media/auto-complete-edition-project?page=${0}&search=${search}&asc`
    );
  },
  clone: (payload) => {
    return _http.post("/api/audio-visual/recording-media/clone", payload);
  },
  exclusionLogical: (id) => {
    return _http.post(
      `/api/audio-visual/recording-media/exclusion-logical-new/${id}`
    );
  },
  // NEW
  store: (payload) => {
    return _http.post("/api/audio-visual/recording-media/store", payload, true);
  },
  getDetails: (id) => {
    return _http.get(`/api/audio-visual/recording-media/get-details/${id}`);
  },
  getRecordingActiveTeacher: (teacher_id) => {
    return _http.get(`/api/audio-visual/recording-media/get-recording-active-teacher/${teacher_id}`);
  },
  finish: (id) => {
    return _http.post(`/api/audio-visual/recording-media/finish/${id}`);
  },
  canFinish: (id) => {
    return _http.get(`/api/audio-visual/recording-media/can-finish/${id}`);
  },
  allCompletedProducts: (id) => {
    return _http.get(`/api/audio-visual/recording-media/all-completed-products/${id}`);
  },
  updateDescription: (id, payload) => {
    return _http.patch(`/api/audio-visual/recording-media?id=${id}`, payload);
  },
  getRecordingBySubject: (search, company_slug = null) => {
    if (company_slug)
      return _http.get(`/api/audio-visual/recording-media/get-recording-by-subject-with-teacher?search=${search}&company_slug=${company_slug}`);
    else
      return _http.get(`/api/audio-visual/recording-media/get-recording-by-subject?search=${search}`);
  },
  canExclusionByOperation: (id) => {
    return _http.get(`/api/audio-visual/recording-media/can-delete-by-operation/${id}`);
  },
  reactivate: (id) => {
    return _http.post(`/api/audio-visual/recording-media/reactivate/${id}`);
  },
  exclusionLogicalByOperartion: (id) => {
    return _http.post(`/api/audio-visual/recording-media/exclusion-logical-by-operation/${id}`);
  },
  getRecordingReworkByOperator: (operator_id) => {
    return _http.get(`/api/audio-visual/recording-media/get-recording-by-operator/${operator_id}`);
  },
  validateRecodingTeacher: (recording_id) => {
    return _http.get(`/api/audio-visual/recording-media/clone/${recording_id}/validate-teacher`);
  },
  getRecordingsCloneByRecording: (id) => {
    return _http.get(`/api/audio-visual/recording-media/get-recordings-clone-by-recording/${id}`);
  },
};
